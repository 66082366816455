import { PhilipsEnvironments, PersonalHealthPhilipsEnvironments, SrcPhilipsEnvironments } from '../../shared/constants';

export const getViewEnum = () => {
  const host = window.location.hostname;

  enum TableauView {
    Overview = '068608d9-8e2a-4b9d-b6cf-9e24dfd1fb84',
    NPSLeadershipStatus = '793ab6cb-b464-438a-b2be-674e5755bd1f',
    NPSTrend = 'c0f11765-940b-41a5-ad07-1ea0d466e357',
    NPSTrendQuarterly = '03f4d12a-d674-4c37-9b0f-f1eda05f7031',
    NPSDrivers = 'de903196-891a-4303-bb7f-43604da8ff03',
    HospitalIDNStrategicPartner = '48b58457-c9be-4927-936e-9a20f83cb1fe',
    ClinicalSegment = '2f9a523b-6e34-4441-b25d-c23c9790474c',
    Marketing = '2bcc0e55-0e29-4b6d-ac67-bd9c32bf6d3c',
    HSSustainability = '9515005a-e93a-4ce0-9675-98a085d26169',
    HSGlobalBrandStrength = '0d90808e-ceaf-4dee-bf22-b91870ab750d',
    HSMarketBrandStrength = '063c9749-9840-414c-951a-62be1001d0da',
    PersonalHealthGlobal = 'b16d4432-d01d-4e4a-97fa-05359ff8a148',
    PersonalHealthCountry = '4ae69b0b-0e8a-442d-84d2-1cd660038059',
    PersonalHealthCategory = '66dfb636-0678-4207-a773-eb227a688b73',
    PersonalHealthOneBlade = 'ca0ae69a-8921-48e7-92b8-d25fe76dd052',
    PersonalHealthIPL = '3efec6b6-876a-4eca-b82a-76490f0eb743',
    PersonalHealthIPLAdditional = '9e90abfd-0e7e-4edb-bc5f-364bfcaf7e8d',
    PersonalHealthBMC = '60e19757-1b81-4834-bcd6-8d4fa2b84075',
    PersonalHealthBMCSimulator = '4e782f31-3ad2-415b-850f-a50056cd6f4d',
    PersonalHealthBSGlobal = '066bd880-b5a0-4dcd-a907-ec1299f5d62d',
    PersonalHealthBSMarket = '8395984d-de64-4721-9109-2167b1669f18',
    PersonalHealthBSDeepDiveByMarkets = 'f40fed50-e2f0-48b2-81b7-6d87826aa64d',
    PersonalHealthBSDeepDiveByAudiences = '0bab4f3f-9157-4183-b9c1-ca88c4713ba3',
    PersonalHealthBSEmployeeDeepDive = '938cde8d-fbc3-4482-a0aa-a5985a6ab838',
    SRCGlobalResults = 'fd4e4043-b6b8-4e44-ad31-c53f63df7202',
    SRCMarketOverview = 'a820c90f-84e2-4a64-ac2a-443b0282e47e',
    SRCCompetitorsPerMarket = '104ce2e8-d786-479c-a34f-a52977defc38',
    SRCCompetitorsGlobally = '3e574cf6-83e6-48d6-8c1b-7845e9b38703',
    SRCCompetitorsGloballyNPS = '9c189638-bf7c-4046-a18f-d7aecee4d5c7',
    SRCDemographics = 'e07ee816-f843-452f-88e4-895c16a55e44',
    SRCAppendixUS = '8a9492a4-411e-4588-aa4c-b906fdc31234',
    SRCAppendixJapan = '084514af-0e51-47ed-af31-d21aaa85bf64',
    SRCRecommendationPatterns = '896a4ec0-5443-404f-9f9b-e21a88cd1c67',
    SRCPurchaseFunnel = '14c63263-dc1b-4226-ad81-9e86f4cee4ad',
    SRCDriverAnalysis = '1e693f10-3253-4300-a428-625bd2789a21',
    SRCSummary = 'c27b2a80-cef6-4e8e-9f13-1d347ad0f3a2',
    SRCProductNPSResults = '2ece5649-ad38-4622-abbd-1454887585cc',
    SRCSnapshot = '78b5e5ba-f448-43ed-a1cf-18519988e845',
    SRCGlobalBrandStrength = 'd982f337-170f-4967-8e0c-f80b04ef303a',
    SRCMarketBrandStrength = '22c17058-2273-4155-adf3-816e287afaa2',
  }

  enum TableauProdView {
    Overview = 'f5b82774-3430-491f-ba3a-4de4b78934ee',
    NPSLeadershipStatus = '6179cd73-c54f-4eb8-89b1-a969e37c4f65',
    NPSTrend = '6efa38de-ab89-467b-8b21-c8317645cd54',
    NPSTrendQuarterly = '078e7eb7-f60e-4efa-be7d-294b025956c8',
    NPSDrivers = '439f17ca-1374-4d8a-842e-839d3bd4f959',
    HospitalIDNStrategicPartner = '7e0f74d7-acd4-42e1-894c-bfe6d031ea9f',
    ClinicalSegment = '9afbfb7e-6a48-467a-bdb3-b460d4c0219a',
    Marketing = 'e2e08f3b-1779-43e6-9588-b836eed56723',
    HSSustainability = '6dab656e-9b96-4ce7-8a51-8af467ba4148',
    HSGlobalBrandStrength = '5f8aa130-d32f-4a9f-9c27-5577dcea5b33',
    HSMarketBrandStrength = '695d1daa-89cf-4d97-bac0-69f9f61cd54a',
    PersonalHealthGlobal = 'f711f03c-98e3-4e9b-9f1c-76d56183914d',
    PersonalHealthCountry = '87b09414-8bef-44be-835e-4dff7bf7c51a',
    PersonalHealthCategory = '06d3e8e2-cb0e-4237-9a5a-4836f8bb0a7d',
    PersonalHealthOneBlade = 'cf238884-6cb2-4d52-97fa-b6f3c12b2625',
    PersonalHealthIPL = '3927c633-4767-47e0-afed-bbd5f98e50c4',
    PersonalHealthIPLAdditional = '7eac245f-925a-454c-961f-501ddd185a6f',
    PersonalHealthBMC = '0955688e-0354-4d89-a4f6-c4fcbad952e6',
    PersonalHealthBMCSimulator = '4e782f31-3ad2-415b-850f-a50056cd6f4d',
    PersonalHealthBSGlobal = 'c196e7db-06f0-48fa-ab27-35586cee2034',
    PersonalHealthBSMarket = '4c2fc728-7d91-401c-9c7c-70805cb510aa',
    PersonalHealthBSDeepDiveByMarkets = '0a9767b7-3702-4fff-9b83-cf9d4c82f2d2',
    PersonalHealthBSDeepDiveByAudiences = 'ef4e1c35-c6e4-477e-a018-f7ebb4a0299c',
    PersonalHealthBSEmployeeDeepDive = '4306e2b6-9ce5-44b7-87bb-d582253d75a0',
    SRCGlobalResults = 'a509827b-c43a-404f-b1b1-b13813dc8518',
    SRCMarketOverview = 'ccf61f7d-3f3b-49f2-9308-0f1640951096',
    SRCCompetitorsPerMarket = 'cd31244a-955d-4686-90db-5ed4d553d9f2',
    SRCCompetitorsGlobally = '9d596042-3572-482d-b80e-d653be8ed0c6',
    SRCCompetitorsGloballyNPS = 'd74de92f-e84a-4612-87dc-6910d3d36677',
    SRCDemographics = 'c27dbfe5-5208-4453-b4c3-e84012d24b4d',
    SRCAppendixUS = '192ce434-5f25-4406-8d03-d57229aee46d',
    SRCAppendixJapan = 'a5df3bbf-370e-4097-b0aa-19967cc51e6e',
    SRCRecommendationPatterns = '6bfb791b-2fca-410d-a506-4abed7a6f19f',
    SRCPurchaseFunnel = '6deeb7bc-e9de-4a4b-b475-c91f054e96e4',
    SRCDriverAnalysis = 'eb6d1f29-3871-4ed6-8fd0-9685ea0f7f0a',
    SRCSummary = '51bc078e-e699-42bd-9240-d039bc06b574',
    SRCProductNPSResults = '531d214f-443a-42d7-b37e-b1536fe79f29',
    SRCSnapshot = 'fe61e7ea-320a-430f-92db-da1e06f3fc0a',
    SRCGlobalBrandStrength = '13881119-ae57-44b3-939d-1ddcfc6d5fa8',
    SRCMarketBrandStrength = '410978aa-db24-4854-af75-67767af7153a',
  }

  return (host === SrcPhilipsEnvironments.Prod || host === PhilipsEnvironments.Prod || host === PersonalHealthPhilipsEnvironments.Prod) ? TableauProdView : TableauView;
};
